<script>
import Main from "@/components/Main";
import Cards from "@/components/Cards";
import Circle from "@/components/Circle.vue";
export default {
  components: {
    Main,
    Cards,
    Circle,
  },
  mounted() {
    document.querySelector("body", "html").style.position = "unset";
  },
};
</script>

<template>
  <section>
    <a href="/resume.pdf" target="_new"><Circle>Resume . Resume .</Circle></a>
    <Main />
    <Cards />
  </section>
</template>
