<template>
  <main>
    <div class="info" data-splitting>
      <h1>Ahmed El<span id="k">k</span>ashef</h1>
      <h3>
        <span><strong>UI/UX</strong> <br />
          <strong>Frontend Designer</strong></span>
        <strong>Cairo, Egypt</strong>
      </h3>
    </div>
    <h1 class="mywork" data-splitting>featured work <span>.</span></h1>
  </main>
</template>

<style lang="scss">
#container {
  perspective: 10px;
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0;
}
#k {
  position: relative;
  display: inline-block;
}
video {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  object-fit: cover;
  z-index: 0;
  mix-blend-mode: multiply;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
</style>
