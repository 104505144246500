<script>
export default {
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoaded = true;
        document.querySelector("body, html").style.overflow = "auto";
      }
    };
  },
};
</script>

<template>
  <transition name="fade">
    <div class="load" v-if="!isLoaded">
      <!-- <span></span> -->
      <svg>
        <text x="50%" y="60%" text-anchor="middle">
          <!-- <h2> -->
          k.
          <!-- </h2> -->
        </text>
      </svg>
    </div>
  </transition>
</template>

<style lang="scss">
body,
html {
  overflow: hidden;
}
@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    // fill: hsl(189, 68%, 75%);
  }

  100% {
    stroke-dasharray: 50% 0;
    stroke-dashoffset: -20%;
    // fill: hsla(189, 68%, 75%, 0%);
  }
}
.load {
  color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background: radial-gradient(circle at 100% 0, black 25%, #282828 65%); //url("/imgs/paper.png") no-repeat center center
  background-size: 120%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s ease;
  &:before {
    content: "";
    animation: grain 8s steps(10) infinite;
    position: fixed;
    top: -100%;
    left: -50%;
    width: 300%;
    height: 300%;
    background: url("/imgs/noisy.png");
    opacity: 0.4;
    z-index: 1;
  }
  svg {
    transform: rotateY(180deg);
    width: 110px;
    text {
      fill: transparent;
      stroke: #fff;
      stroke-width: 3;
      animation: textAnimate 4s infinite alternate;
      font-size: 8em;
      // display: flex;
      // flex-flow: row-reverse;
      // gap: 5px;
      // align-items: baseline;
      // transform: rotateY(180deg);
      text-transform: capitalize;
    }
  }
  span {
    --size: 30px;
    width: var(--size);
    height: var(--size);
    background: #fff;
    display: block;
    border-radius: 50px;
    transition: 0.5s ease-in-out;
    animation: bounce 1.5s infinite linear;
    transform-origin: bottom;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-25px);
  }
  50% {
    transform: translateY(-45px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
