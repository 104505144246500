//MAIN CSS//
import "@/assets/css/main.scss";
//VUE//
import { createApp } from "vue";
import app from "./App.vue";
//scrollbar//
// import PerfectScrollbar from "vue3-perfect-scrollbar";
// import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
//router//
import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Project from "@/views/Project.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/project:title",
    name: "Project",
    props: true,
    component: Project,
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: "history",
});
export default router;

createApp(app).use(router).mount(".app");
