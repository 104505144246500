<script>
import json from "../../db.json";
export default {
  data() {
    return {
      cards: json.cards,
    };
  },
  mounted() {
    const blocks = document.querySelectorAll(".brick"),
      els = document.querySelectorAll(".screen"),
      more = document.querySelectorAll(".more"),
      outline = document.querySelector(".cursor-dot-outline");

    blocks.forEach((block) => {
      block.addEventListener("mousemove", (e) => {
        els.forEach((el) => {
          el.style.top = e.pageY + "px";
          el.style.left = e.pageX + "px";
        });
      });
      //
      block.addEventListener("mouseover", () => {
        more.forEach((m) => {
          m.style.opacity = "1";
          outline.style.border = "none";
        });
      });
      block.addEventListener("mouseout", () => {
        more.forEach((m) => {
          m.style.opacity = "0";
          outline.style.border = "2px solid #e58080";
        });
      });
    });
  },
};
</script>

<template>
  <div class="masonry">
    <a
      :href="'project' + card.title"
      v-for="card in cards"
      :key="card.id"
      class="brick"
      id="show-modal"
    >
      <!---->
      <figure class="screen" :class="card.class">
        <img :src="card.img" alt="" />
      </figure>
      <!---->
      <div class="overlay">
        <h1>
          {{ card.title }} <br />
          <small>{{ card.type }}</small>
        </h1>
      </div>
    </a>
  </div>
  <!--squiggly.svg-->
  <svg style="display: none" xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="squiggly-0">
        <feTurbulence
          id="turbulence"
          baseFrequency="0.02"
          numOctaves="3"
          result="noise"
          seed="0"
        />
        <feDisplacementMap
          id="displacement"
          in="SourceGraphic"
          in2="noise"
          scale="6"
        />
      </filter>
      <filter id="squiggly-1">
        <feTurbulence
          id="turbulence"
          baseFrequency="0.02"
          numOctaves="3"
          result="noise"
          seed="1"
        />
        <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
      </filter>
      <filter id="squiggly-2">
        <feTurbulence
          id="turbulence"
          baseFrequency="0.02"
          numOctaves="3"
          result="noise"
          seed="2"
        />
        <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
      </filter>
      <filter id="squiggly-3">
        <feTurbulence
          id="turbulence"
          baseFrequency="0.02"
          numOctaves="3"
          result="noise"
          seed="3"
        />
        <feDisplacementMap in="SourceGraphic" in2="noise" scale="8" />
      </filter>
      <filter id="squiggly-4">
        <feTurbulence
          id="turbulence"
          baseFrequency="0.02"
          numOctaves="3"
          result="noise"
          seed="4"
        />
        <feDisplacementMap in="SourceGraphic" in2="noise" scale="6" />
      </filter>
    </defs>
  </svg>
  <!---->
</template>

<style lang="scss">
@import "../assets/css/mixins";
.modal {
  // &__bg {
  //   background: linear-gradient(45deg, black, transparent);
  // }
  &__content {
    // position: relative;
    // height: 100vh;
    // padding: 2em 4em;
    // color: #fff;
    // justify-content: space-between;
    // display: flex;
    // flex-flow: row;
    // gap: 1em;
    // overflow: auto;
    @include media(sm) {
      flex-flow: column;
      padding: 0 2em;
    }
    .about {
      // display: flex;
      // align-items: baseline;
      // gap: 15px;
      // list-style: none;
      // margin: 0;
      // padding: 0;
      // flex-flow: column;
      // position: sticky;
      // top: 10%;
      // flex: auto;
      // align-self: flex-end;
      @include media(sm) {
        position: relative;
        top: 0;
      }
      .tools {
        gap: 1em;
        li {
          display: flex;
          flex-flow: row;
          align-items: center;
          gap: 0.3em;
        }
      }
      > :not(.tools li)li {
        line-height: unset !important;
        background: none;
        padding: 5px 0;
        &:first-child {
          font-size: 5em;
          inline-size: min-content;
          @include media(xs) {
            font-size: 3em;
          }
        }
      }
      .view {
        a {
          font-size: 2em;
          align-items: flex-end;
          line-height: 1;
          @include media(xs) {
            font-size: 1.5em;
          }
          img {
            width: 25px;
          }
        }
      }
    }
  }
  // .mobile {
  //   display: flex;
  //   flex-flow: wrap;
  //   gap: 2em;
  //   justify-content: flex-end;
  //   img {
  //     width: 33.333% !important;
  //     @include media(xs) {
  //       width: 100% !important;
  //     }
  //     @include media(sm) {
  //       width: calc(50% - 1em) !important;
  //     }
  //   }
  // }
}
.screens {
  @include media(sm) {
    max-width: 100%;
  }
}
</style>
