<script>
import Loader from "@/components/Loader";
//splitting JS//
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

export default {
  components: {
    Loader,
  },
  mounted() {
    Splitting();
  },
};
</script>
<template>
  <video src="imgs/loop.mp4" muted autoplay loop></video>
  <!---->
  <router-view v-slot="{ Component, route }">
    <!-- <div class="cursor-dot-outline">
      <span class="more"><img src="imgs/arrow-top.png" alt=""></span>
    </div> -->
    <!-- <div class="cursor-dot"></div> -->
    <Loader />
    <transition name="slide" mode="out-in">
      <component :is="Component" :key="route.path" />
    </transition>
  </router-view>
</template>
