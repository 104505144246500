<script>
import json from "../../db.json";
export default {
  data() {
    return {
      cards: json.cards,
    };
  },
  methods: {
    getCard(title) {
      let data = this.cards;
      return data.filter((item) => {
        return item.title == title;
      });
    },
  },
  mounted() {
    document.querySelector("body").style.position = "fixed";
  },
};
</script>

<template>
  <section>
    <div
      class="modal"
      v-for="prjkt in getCard($route.params.title)"
      :key="prjkt.id"
    >
      <div class="modal__bg"></div>
      <a href="/" class="modal__close"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path
            d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
          />
        </svg>
      </a>
      <div class="modal__content" :class="prjkt.class">
        <!--Info-->
        <ul class="about">
          <li v-for="info in prjkt.infos" :key="info">
            {{ info }}
          </li>
          <!--Tools-->
          <li>
            <span>Tools:</span>
            <ul class="tools">
              <li v-for="tool in prjkt.tools" :key="tool.id">
                <span>{{ tool.toolName }}</span>
                <img :src="tool.toolIcon" alt="" />
              </li>
            </ul>
          </li>
          <!---->
          <li class="view" v-if="prjkt.link && prjkt.link.length > 0">
            <a :href="prjkt.link" target="_new"
              ><span>View Live</span>
              <img src="/imgs/link.png" alt="link" width="20"
            /></a>
          </li>
        </ul>
        <!--Screens-->
        <div class="screens">
          <div class="shots">
            <img
              v-for="gall in prjkt.gallery"
              :key="gall"
              :src="gall"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "../assets/css/mixins";
.modal {
  position: relative;
  // &__bg {
  //   background: linear-gradient(45deg, black, transparent);
  // }
  &__content {
    position: relative;
    height: 100vh;
    padding: 2em 14em;
    color: #fff;
    justify-content: space-between;
    display: flex;
    flex-flow: column;
    gap: 1em;
    overflow: auto;
    @include media(sm) {
      flex-flow: column;
      padding: 0 2em;
    }
    .about {
      display: flex;
      align-items: baseline;
      gap: 15px;
      list-style: none;
      margin: 0;
      padding: 0;
      flex-flow: column;
      // position: sticky;
      // top: 10%;
      // flex: 1;
      // align-self: flex-end;
      @include media(sm) {
        position: relative;
        top: 0;
      }
      .tools {
        gap: 1em;
        li {
          display: flex;
          flex-flow: row;
          align-items: center;
          gap: 0.3em;
        }
      }
      > :not(.tools li)li {
        line-height: unset !important;
        background: none;
        padding: 5px 0;
        &:first-child {
          font-size: 5em;
          inline-size: min-content;
          @include media(xs) {
            font-size: 3em;
          }
        }
      }
      .view {
        a {
          font-size: 2em;
          align-items: flex-end;
          line-height: 1;
          @include media(xs) {
            font-size: 1.5em;
          }
          img {
            width: 25px;
          }
        }
      }
    }
  }
  .mobile {
    display: flex;
    flex-flow: column;
    gap: 2em;
    .shots {
      img {
        width: 100% !important;
        // @include media(sm) {
        //   width: calc(50% - 1em) !important;
        // }
      }
    }

    .about {
      position: unset;
      align-self: auto;
    }
  }
}
.screens {
  max-width: 100%;
  flex: 3;
  @include media(sm) {
    max-width: 100%;
  }
}
</style>
